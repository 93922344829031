<template>
  <div>
    <v-expansion-panels v-model="panel" :key="panelKey" focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <task-banner :task="task"></task-banner>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-expansion-panels>
            <!-- task Timeline & Calendar -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span :class="titleClass">
                        Status
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <v-fade-transition leave-absolute>
                        <span v-if="!open">
                          <span :class="titleClass">
                            Timeline, Calendar
                          </span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <task-chart :task="task"></task-chart>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- task details -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span :class="titleClass">
                  {{ taskDetailsHeader }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <form-task :task="task" :record="record"></form-task>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- task actions -->
          <v-tabs class="mt-3" show-arrows grow>
            <!-- my task action OK (Accept/Approve/Done) -->
            <base-tooltip-button
              :disabled="!canTaskAccept"
              :color="acceptColorClass"
              @click="onShowAcceptUserTaskDialog"
            >
              {{ labelCommandTaskAccept }}
              <template v-slot:icon-name>
                {{ iconTaskAccept }}
              </template>
              <template v-slot:toolTip>
                {{ tooltipAccept }}
              </template>
            </base-tooltip-button>

            <!-- my task action Cancel (Cancel/Reject) -->
            <base-tooltip-button
              :disabled="!canTaskReject"
              :color="cancelColorClass"
              @click="onShowRejectUserTaskDialog"
            >
              {{ labelCommandTaskReject }}
              <template v-slot:icon-name>
                {{ iconTaskReject }}
              </template>
              <template v-slot:toolTip>
                {{ tooltipReject }}
              </template>
            </base-tooltip-button>

            <v-divider
              vertical
              v-if="visibleTaskAccept || visibleTaskReject"
            ></v-divider>

            <!-- Command Task Take -->
            <base-tooltip-button
              v-if="visibleTaskTake"
              :disabled="!canTaskTake"
              @click="onShowTakeUserTaskDialog"
            >
              {{ labelTaskTake }}
              <template v-slot:icon-name>
                {{ iconTaskTake }}
              </template>
              <template v-slot:toolTip>
                {{ tooltipTaskTake }}
              </template>
            </base-tooltip-button>

            <!-- Command Task Release -->
            <base-tooltip-button
              v-if="visibleTaskRelease"
              :disabled="!canTaskRelease"
              :loading="visibleTaskReleaseProgress"
              @click="onShowReleaseUserTaskDialog"
            >
              {{ labelTaskRelease }}
              <template v-slot:icon-name>
                {{ iconTaskRelease }}
              </template>
              <template v-slot:toolTip>
                {{ tooltipTaskRelease }}
              </template>
            </base-tooltip-button>

            <!-- Command Task Reassign -->
            <base-tooltip-button
              v-if="visibleTaskReassign"
              :disabled="!canTaskReassign"
              @click="onUserTaskReassign"
            >
              {{ labelTaskReassign }}
              <template v-slot:icon-name>
                {{ iconTaskReassign }}
              </template>
              <template v-slot:toolTip>
                {{ tooltipTaskReassign }}
              </template>
            </base-tooltip-button>

            <v-divider
              vertical
              v-if="
                visibleTaskAccept ||
                  visibleTaskReject ||
                  visibleTaskTake ||
                  visibleTaskRelease
              "
            ></v-divider>

            <!-- Jump to project in project module (tab)/associated record in folder module -->
            <base-drop-down-menu-button>
              <template v-slot:iconName>
                {{ iconRun }}
              </template>
              <template v-slot:default>
                Jump to
              </template>
              <template v-slot:commands>
                <!-- Jump to associated record in Folders Tab -->
                <base-menu-item
                  :to="folderModuleRecordRouteLocation(record)"
                  :disabled="!canNavigateToFolderModuleRecord(record)"
                >
                  <template v-slot:iconName>
                    {{ commandSelectRecordIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandSelectRecordLabel(record) }}
                    </div>
                  </template>
                  <template v-slot:default>
                    {{ commandSelectRecordLabel(record) }}
                  </template>
                </base-menu-item>

                <!-- Jump to associated project in Projects Tab -->
                <base-menu-item
                  v-if="visibleJumpToProject"
                  :to="projectRouteLocation(projectId)"
                  :disabled="!canNavigateToProject(projectId)"
                >
                  <template v-slot:iconName>
                    {{ commandNavigateToProjectIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNavigateToProjectLabel(projectName) }}
                    </div>
                  </template>
                  <template v-slot:default>
                    {{ commandNavigateToProjectLabel(projectName) }}
                  </template>
                </base-menu-item>
              </template>
            </base-drop-down-menu-button>
          </v-tabs>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Accept User task Dialog -->
    <accept-user-task-dialog
      :task="task"
      :visible="visibleDialogAcceptUserTask"
      :perform-action="performAcceptUserTask"
      @close="onCloseAcceptUserTaskDialog"
    ></accept-user-task-dialog>

    <!-- reject User task Dialog -->
    <reject-user-task-dialog
      :task="task"
      :visible="visibleDialogRejectUserTask"
      :perform-action="performRejectUserTask"
      @close="onCloseRejectUserTaskDialog"
    ></reject-user-task-dialog>

    <!-- Take User task Dialog -->
    <take-user-task-dialog
      :task="task"
      :visible="visibleDialogTakeUserTask"
      :perform-action="performTakeUserTask"
      @close="onCloseTakeUserTaskDialog"
    ></take-user-task-dialog>

    <!-- Release User task Dialog -->
    <release-user-task-dialog
      :task="task"
      :visible="visibleDialogReleaseUserTask"
      :perform-action="performReleaseUserTask"
      @close="onCloseReleaseUserTaskDialog"
    ></release-user-task-dialog>

    <!-- Assign User Dialog -->
    <assign-user-dialog
      :task="task"
      :visible="visibleDialogAssignUser"
      :perform-action="onUserAssignment"
      @close="onCloseAssignUserTaskDialog"
    ></assign-user-dialog>

    <!-- snackbar -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
// model
import { moduleNames } from "@/model/solution/moduleModel";
import {
  findCommandTaskAcceptColorClass,
  findCommandTaskRejectColorClass,
  findUserTaskCommand,
  findUserTaskType,
  userTaskCommandType,
  userTaskLabel
} from "@/model/workflow/task/taskModel";
import {
  findRecordType,
  findRecordTypeIcon,
  recordType
} from "@/model/record/recordModel";
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";

// services
import {
  canAcceptTask,
  canReassignTask,
  canRejectTask,
  canReleaseTask,
  canTakeTask
} from "@/services/inbox/taskService";
import { titleClass } from "@/services/text/textService";

// design
import { iconRun } from "@/design/icon/iconConst";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("inbox");

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";
import { appModuleNavigatable } from "@/mixins/shared/navigatable/appModuleNavigatable";
import { taskMixin } from "@/mixins/shared/workflow/task/taskMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";
import { projectNavigatable } from "@/mixins/shared/navigatable/projectNavigatable";
import { folderRecordNavigatable } from "@/mixins/shared/navigatable/folderRecordNavigatable";

export default {
  name: "InboxDetails",
  inheritAttrs: false,
  mixins: [
    menuItemSettingsMixin,
    buttonSettingsMixin,
    deleteSettingsMixin,
    snackbarableMixin,
    moduleItemNavigatable,
    appModuleNavigatable,
    taskMixin,
    userMixin,
    projectNavigatable,
    folderRecordNavigatable
  ],
  components: {
    AcceptUserTaskDialog: () =>
      import("@/components/task/dialogs/AcceptUserTaskDialog"),
    RejectUserTaskDialog: () =>
      import("@/components/task/dialogs/RejectUserTaskDialog"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    FormTask: () => import("@/components/task/FormTask"),
    TaskBanner: () => import("@/components/task/TaskBanner"),
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    TakeUserTaskDialog: () =>
      import("@/components/task/dialogs/TakeUserTaskDialog"),
    ReleaseUserTaskDialog: () =>
      import("@/components/task/dialogs/ReleaseUserTaskDialog"),
    TaskChart: () => import("@/components/task/TaskChart")
  },
  data() {
    return {
      visibleTaskTakeProgress: false,
      visibleTaskReleaseProgress: false,
      panelKey: 1,
      panel: undefined,
      visibleDialogAssignUser: false,
      visibleDialogTakeUserTask: false,
      visibleDialogReleaseUserTask: false,
      visibleDialogAcceptUserTask: false,
      visibleDialogRejectUserTask: false,
      iconRun: iconRun
    };
  },
  computed: {
    ...mapState({ tasks: "tasks", record: "record" }),
    /**
     * Get Title of the Inbox details
     * @return {String|string}
     */
    title() {
      return this.taskName;
    },

    /**
     * command Accept
     * @return {{label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}}
     */
    commandAccept() {
      return this.userTaskType?.commandAccept;
    },

    /**
     * command Reject
     * @return {{label: string, icon: string, color: {variantType: string, variantNumber: number, name: string}}}
     */
    commandReject() {
      return this.userTaskType?.commandReject;
    },

    /**
     * label Command Task Accept
     * @return {String|string} label Command Task Accept
     */
    labelCommandTaskAccept() {
      return this.commandAccept?.label ?? "OK";
    },

    /**
     * label Command Task Reject
     * @return {String|string} label Command Task Reject
     */
    labelCommandTaskReject() {
      return this.commandReject?.label ?? "Reject";
    },

    /**
     * icon Task Accept
     * @return {String|string|undefined}
     */
    iconTaskAccept() {
      return this.commandAccept?.icon;
    },

    /**
     * icon Task Reject
     * @return {String|string|undefined}
     */
    iconTaskReject() {
      return this.commandReject?.icon;
    },

    /**
     * Checks if the task can be accepted.
     * @return {boolean} Returns true if the task can be accepted, false otherwise.
     */
    visibleTaskAccept() {
      return this.canTaskAccept;
    },

    /**
     * Checks whether the task can be accepted by the current user.
     * @return {boolean} Returns true if the user can accept the task, false otherwise.
     */
    canTaskAccept() {
      return canAcceptTask(this.task, this.principal?.actorId ?? -1);
    },

    /**
     * Retrieves the label for the "Take Task" action.
     * @return {string} The label text for taking the task.
     */
    labelTaskTake() {
      return findUserTaskCommand(userTaskCommandType.take)?.label;
    },

    /**
     * Retrieves the tooltip text for the "Take Task" action.
     * @return {string} The tooltip description for taking the task.
     */
    tooltipTaskTake() {
      return findUserTaskCommand(userTaskCommandType.take)?.description;
    },

    /**
     * Retrieves the icon for the "Take Task" action.
     * @return {string} The icon associated with taking the task.
     */
    iconTaskTake() {
      return findUserTaskCommand(userTaskCommandType.take)?.icon;
    },

    /**
     * Checks if the task can be taken.
     * @return {boolean} Returns true if the task can be taken, false otherwise.
     */
    visibleTaskTake() {
      return this.canTaskTake;
    },

    /**
     * Checks whether the task can be taken by the current user.
     * @return {boolean} Returns true if the user can take the task, false otherwise.
     */
    canTaskTake() {
      return canTakeTask(this.task);
    },

    /**
     * Retrieves the label for the "Release Task" action.
     * @return {string} The label text for releasing the task.
     */
    labelTaskRelease() {
      return findUserTaskCommand(userTaskCommandType.release)?.label;
    },

    /**
     * Retrieves the tooltip text for the "Release Task" action.
     * @return {string} The tooltip description for releasing the task.
     */
    tooltipTaskRelease() {
      return findUserTaskCommand(userTaskCommandType.release)?.description;
    },

    /**
     * Retrieves the icon for the "Release Task" action.
     * @return {string} The icon associated with releasing the task.
     */
    iconTaskRelease() {
      return findUserTaskCommand(userTaskCommandType.release)?.icon;
    },

    /**
     * Checks if the task can be released.
     * @return {boolean} Returns true if the task can be released, false otherwise.
     */
    visibleTaskRelease() {
      return this.canTaskRelease;
    },

    /**
     * Checks whether the task can be released by the current user.
     * @return {boolean} Returns true if the user can release the task, false otherwise.
     */
    canTaskRelease() {
      return canReleaseTask(this.task, this.principal?.actorId ?? -1);
    },

    /**
     * Retrieves the label for the "Reassign Task" action.
     * @return {string} The label text for reassigning the task.
     */
    labelTaskReassign() {
      return findUserTaskCommand(userTaskCommandType.reassign)?.label;
    },

    /**
     * Retrieves the tooltip text for the "Reassign Task" action.
     * @return {string} The tooltip description for reassigning the task.
     */
    tooltipTaskReassign() {
      return findUserTaskCommand(userTaskCommandType.reassign)?.description;
    },

    /**
     * Retrieves the icon for the "Reassign Task" action.
     * @return {string} The icon associated with reassigning the task.
     */
    iconTaskReassign() {
      return findUserTaskCommand(userTaskCommandType.reassign)?.icon;
    },

    /**
     * Checks if the task can be reassigned.
     * @return {boolean} Returns true if the task can be reassigned, false otherwise.
     */
    visibleTaskReassign() {
      return this.canTaskReassign;
    },

    /**
     * Checks whether the task can be reassigned by the current user.
     * @return {boolean} Returns true if the user can reassign the task, false otherwise.
     */
    canTaskReassign() {
      return canReassignTask(this.task);
    },

    /**
     * Command accepts Color Class
     * @return {String|string|undefined}
     */
    acceptColorClass() {
      return findCommandTaskAcceptColorClass(this.taskTypeId);
    },

    /**
     * Command cancel Color Class
     * @return {String|string|undefined}
     */
    cancelColorClass() {
      return findCommandTaskRejectColorClass(this.taskTypeId);
    },

    visibleTaskReject() {
      return this.canTaskReject;
    },

    /**
     * can user execute command Task Reject
     * @return {boolean} true when user can execute command Task Reject
     */
    canTaskReject() {
      return canRejectTask(this.task, this.principal?.actorId ?? -1);
    },

    /**
     * tooltip Task Accept
     * @return {string} tooltip
     */
    tooltipAccept() {
      return `${this.commandAccept?.label}: '${this.taskName}' ${userTaskLabel}`;
    },

    /**
     * tooltip Task Reject
     * @return {string} tooltip
     */
    tooltipReject() {
      return `${this.commandReject?.label}: '${this.taskName}' ${userTaskLabel}`;
    },

    /**
     * Select Record Icon Name
     * remarks: overwrite commandSelectRecordIconName
     * @return {string}
     */
    commandSelectRecordIconName() {
      return findRecordTypeIcon(this.record?.recordTypeId ?? recordType.RECORD);
    },

    /**
     * title Class
     * @return {string}
     */
    titleClass() {
      return titleClass();
    },

    /**
     * project Details Header
     * @return {string}
     */
    taskDetailsHeader() {
      return `Task Details`;
    }
  },
  methods: {
    ...mapActions({
      acceptTask: "acceptTask",
      rejectTask: "rejectTask",
      takeTask: "takeTask",
      releaseTask: "releaseTask"
    }),

    /**
     * handle event onShowTakeUserTaskDialog
     * @return {Promise<void>}
     */
    onShowTakeUserTaskDialog() {
      this.visibleDialogTakeUserTask = true;
    },

    /**
     * close Dialog Take User Task
     */
    onCloseTakeUserTaskDialog() {
      this.visibleDialogTakeUserTask = false;
    },

    /**
     * perform Take User Task
     * @param {string} note
     * @param {boolean} sendNotification
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async performTakeUserTask(note, sendNotification) {
      try {
        const cmd = findUserTaskCommand(userTaskCommandType.take);
        const action = cmd?.label;

        const payload = {
          taskId: this.taskId,
          note: note,
          sendNotification: sendNotification
        };

        const result = await this.takeTask(payload);

        return result
          ? createActionResultSuccess(action)
          : createActionResultError(action);
      } catch (e) {
        console.error(e);
        this.showSnackbarError(e, true);
      }
    },

    /**
     * handle event onShowAcceptUserTaskDialog
     * @return {Promise<void>}
     */
    onShowAcceptUserTaskDialog() {
      this.visibleDialogAcceptUserTask = true;
    },

    /**
     * close Accept Take User Task
     */
    onCloseAcceptUserTaskDialog() {
      this.visibleDialogAcceptUserTask = false;
    },

    /**
     * perform Accept User Task
     * @param {string} note
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async performAcceptUserTask(note) {
      try {
        const taskType = findUserTaskType(this.task?.typeId ?? -1);
        const action = taskType?.commandAccept?.label ?? "Accept";

        const payload = {
          taskId: this.taskId,
          note: note
        };

        console.log(
          `${this.$options.name}.performAcceptUserTask() payload:`,
          payload
        );

        const result = await this.acceptTask(payload);

        console.log(
          `${this.$options.name}.performAcceptUserTask() result:`,
          result
        );

        // navigate to the first task if a successful result
        if (result) {
          if ((this.tasks?.length ?? 0) > 0) {
            await this.navigateToModuleItem(this.tasks[0].taskId);
          } else {
            await this.navigateToAppModule(moduleNames.Tasks);
          }
        }

        return result
          ? createActionResultSuccess(action)
          : createActionResultError(action);
      } catch (e) {
        console.error(e);
        this.showSnackbarError(e, true);
      }
    },

    /**
     * handle event onShowRejectUserTaskDialog
     * @return {Promise<void>}
     */
    onShowRejectUserTaskDialog() {
      this.visibleDialogRejectUserTask = true;
    },

    /**
     * close Reject Take User Task
     */
    onCloseRejectUserTaskDialog() {
      this.visibleDialogRejectUserTask = false;
    },

    /**
     * perform Reject User Task
     * @param {string} note
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async performRejectUserTask(note) {
      try {
        const taskType = findUserTaskType(this.task?.typeId ?? -1);
        const action = taskType?.commandReject?.label ?? "Reject";

        const payload = {
          taskId: this.taskId,
          note: note
        };

        console.log(
          `${this.$options.name}.performRejectUserTask() payload:`,
          payload
        );

        const result = await this.rejectTask(payload);

        console.log(
          `${this.$options.name}.performRejectUserTask() result:`,
          result
        );

        // navigate to the first task if a successful result
        if (result) {
          if ((this.tasks?.length ?? 0) > 0) {
            await this.navigateToModuleItem(this.tasks[0].taskId);
          } else {
            await this.navigateToAppModule(moduleNames.Tasks);
          }
        }

        return result
          ? createActionResultSuccess(action)
          : createActionResultError(action);
      } catch (e) {
        console.error(e);
        this.showSnackbarError(e, true);
      }
    },

    /**
     * close Dialog Take User Task
     */
    onCloseReleaseUserTaskDialog() {
      this.visibleDialogReleaseUserTask = false;
    },

    /**
     * perform Release User Task
     * @param {string} note
     * @param {boolean} sendNotification
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async performReleaseUserTask(note, sendNotification) {
      try {
        const cmd = findUserTaskCommand(userTaskCommandType.release);
        const action = cmd?.label;

        const payload = {
          taskId: this.taskId,
          note: note,
          sendNotification: sendNotification
        };

        const result = await this.releaseTask(payload);

        return result
          ? createActionResultSuccess(action)
          : createActionResultError(action);
      } catch (e) {
        console.error(e);
        this.showSnackbarError(e, true);
      }
    },

    /**
     * handle event onShowReleaseUserTaskDialog
     * @return {Promise<void>}
     */
    onShowReleaseUserTaskDialog() {
      this.visibleDialogReleaseUserTask = true;
    },

    /**
     * handle event onUserTaskReassign
     */
    onUserTaskReassign() {
      this.visibleDialogAssignUser = true;
    },

    /**
     * Jump to Record in Folder Tab Label
     * @param record
     * @return {string|string}
     */
    commandSelectRecordLabel(record) {
      return `${
        findRecordType(record?.recordTypeId ?? recordType.RECORD)?.name
      }: ${record?.name}`;
    }
  },
  watch: {
    task(newTask) {
      console.log(`watch ${this.$options.name}.task:`, newTask);
      console.log(
        `watch ${this.$options.name} this.userTaskType:`,
        this.userTaskType
      );
    },
    panel() {
      //This force re-renders the component attached to this key, necessary to load chart.
      this.panelKey += 1;
    }
  }
};
</script>
